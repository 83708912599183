var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"small-12 medium-4 columns tight"},[_c('div',{staticClass:"small-12 columns tight"},[_c('p',{staticStyle:{"padding-top":"0.5rem","margin-bottom":"1rem","margin-right":"2rem","font-size":"0.9rem"}},[_vm._v(" "+_vm._s(_vm.$t("sections.bookIt.currentPrice"))+": "),_c('strong',[_vm._v(_vm._s(_vm.currencyLocale(_vm.product.price)))])])])]),(_vm.loaded)?_c('table',{staticClass:"table",class:{single: _vm.units.length === 1}},[_c('thead',[_c('tr',[_vm._m(0),_vm._l((_vm.sortedProduct),function(unit){return _c('th',{key:unit.SKU,staticClass:"ml-size"},[_vm._v(" "+_vm._s(unit.unitField.value)+" "),(
              _vm.isCollection
                ? !_vm.collection.settings.ignoreInventory
                : !_vm.program.settings.ignoreInventory
            )?_c('span',[_vm._v(_vm._s(unit.inventory))]):_vm._e()])}),_c('th',{staticClass:"ml-fill"})],2)]),_c('tbody',_vm._l((_vm.shippingAddresses),function(loc){return _c('tr',{key:loc._id},[_c('td',{staticClass:"ml-loc"},[_vm._v(_vm._s(loc.name))]),_vm._l((_vm.sortedProduct),function(item){return _c('td',{key:item._id,staticClass:"ml-input",class:{
            cartvalue:
              _vm.cart.details[item.SKU] &&
              _vm.cart.details[item.SKU].locations[loc._id] &&
              _vm.selectedQtys[item.SKU]
          }},[(_vm.selectedQtys[item.SKU])?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedQtys[item.SKU].locations[loc._id]),expression:"selectedQtys[item.SKU].locations[loc._id]"}],staticClass:"purchasable",attrs:{"data-id":item._id,"data-sku":item.SKU,"data-location":loc._id,"type":"number","placeholder":"0","min":_vm.unitMinMax[item.SKU] ? _vm.unitMinMax[item.SKU].min : 0,"max":_vm.unitMinMax[item.SKU] ? _vm.unitMinMax[item.SKU].max : 0,"title":_vm.cart.items[item.SKU] &&
              _vm.selectedQtys[item.SKU] &&
              _vm.selectedQtys[item.SKU].qty &&
              _vm.cart.details[item.SKU].locations[loc._id] ===
                _vm.selectedQtys[item.SKU].locations[loc._id]
                ? 'items already in cart'
                : '',"disabled":_vm.respectInventory && item.inventory < 1,"number":""},domProps:{"value":(_vm.selectedQtys[item.SKU].locations[loc._id])},on:{"change":_vm.updateItemQtys,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selectedQtys[item.SKU].locations, loc._id, $event.target.value)}}}):_vm._e()])}),_c('td',{staticClass:"ml-fill"})],2)}),0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"ml-title"},[_vm._v("Locations"),_c('br')])}]

export { render, staticRenderFns }